import React from "react";
import styled from "styled-components";

const HeaderComponent = ({ title, background }) => {
  return (
    <Container background={background}>
      <HeaderText>{title}</HeaderText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 400px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.background ? props.background : "#fef4ea;"};
`;

const HeaderText = styled.h1`
  display: inline-block;

  margin-top: 0px;
  font-family: "Nunito Sans";
  font-weight: 600;
  font-size: 46px;
  text-align: center;
  margin-top: 5px;
  color: #333333;
`;

export default HeaderComponent;
