import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { useIntl } from "gatsby-plugin-intl";
import HeaderComponent from "../components/HeaderComponent";
import ProjectCard from "../components/Projects/ProjectCard";

const BlogOverview = (props) => {
  const intl = useIntl();
  const projects = props.pageContext.projects;
  return (
    <Layout>
      <Helmet title={`Projects| ${config.siteTitle}`} />
      <HeaderComponent title={intl.formatMessage({ id: "projects" })} />
      <NewsItemsContainer>
        {projects.map((item) => {
          return <ProjectCard key={item.id} {...item} />;
        })}
      </NewsItemsContainer>
    </Layout>
  );
};

const NewsItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  justify-content: space-between;
  margin: auto;

  margin-top: 40px;
  margin-bottom: 40px;
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    width: 100%;
    padding: 20px;
    position: relative;
    bottom: 0px;
  }
`;

export default BlogOverview;
